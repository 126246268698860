import { Container } from './styles';
import whatsapp from '../../assets/whatsappWhite.svg'
import ReactPixel from 'react-facebook-pixel'

export function OrcamentoButton() {

    function pixelPageView() {
        ReactPixel.init('3031292527091334')
        ReactPixel.pageView()
    }

    return (
        <Container href="https://www.google.com" onClick={pixelPageView}>
            <div>
                <div>
                    <p>Faça um orçamento</p>
                    <img src={whatsapp} alt="Faça um orçamento" />
                </div>
            </div>
        </Container>
    )
}