import { Container } from "./styles";

import arrow from '../../assets/arrowIcon.svg'


export function Experience() {
    return (
        <Container>
            <h1>Experience</h1>
            <div>
                <span/>
                <span/>
                <span/>
            </div>
            <img src={arrow} alt="Arrow" />
        </Container>
    );
}