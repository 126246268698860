import { Header } from '../../components/Header'
import { Body } from '../../components/Body'
import { Footer } from '../../components/Footer'

export function Trafego() {
    return (
        <>
            <Header />
            <Body></Body>
            <Footer />
        </>
    )
}