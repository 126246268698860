import styled from 'styled-components';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;

    >img{
        border-radius: 10px;
        margin-bottom: 5rem;
        width: 80%;

        @media (min-width: 420px) {
            width: 60%;
            height: 60%;
        }
    }
`;

export const H1 = styled.h1`
    &:first-child{
        margin-top: 13rem;
    }

    width: 80%;
    font-size: 27px;
    text-align: center;
    margin: 4rem 0;

    @media (min-width: 420px) {
        &:first-child{
            margin-top: 10rem;
        }

        margin: 2rem 0;
    }
`;

export const P = styled.p`
    width: 80%;
    font-size: 16px;
    text-align: center;
`;

export const Social = styled.div`

    width: 50rem;
    height: 50rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;

    margin: 5rem 0;
    
    @media (min-width: 420px) {
        height: 15rem;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    div{
        display: flex;
        align-items: center;
        justify-content: center;

        div{
            width: 90%;
            height: 90%;
            border-radius: 5px;
            border: 1px solid var(--gray-100);
            
            img{
                margin: 2rem;
            }
        }
    }
`;