import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 900px;

  background-color: var(--white);

  > img {
    width: 35rem;

    border-radius: 50%;
    border: 1px solid var(--gray-100);
    transform: rotate(-35deg);

    margin-top: 16rem;

    @media (min-width: 420px) {
      width: 25rem;
    }
  }
`;

export const H1 = styled.h1`
  width: 80%;
  font-size: 3rem;
  font-weight: 500;
  text-align: center;

  margin: 4rem;

  @media (min-width: 420px) {
    &:first-child {
      margin-top: 10rem;
    }

    margin: 2rem 0;
  }
`;

export const H2 = styled.h1`
  width: 80%;
  font-size: 2.8rem;
  font-weight: 500;
  text-align: center;

  margin: 4rem;

  @media (min-width: 420px) {
    margin: 2rem 0;
  }
`;

export const P = styled.p`
  width: 80%;
  font-size: 2rem;
  text-align: center;
`;

export const Social = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media (min-width: 420px) {
    height: 15rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  a {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 1rem;

    span {
      border-radius: 5px;
      border: 1px solid var(--gray-100);

      transition: border 0.3s;

      svg {
        width: 4rem;
        height: 4rem;
        margin: 2rem;
      }

      &:hover {
        border-color: var(--gray-300);
      }
    }
  }
`;

export const Project = styled.div`
  width: 100%;
  height: 20rem;

  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid var(--gray-100);
  border-radius: 10px;

  margin-bottom: 2rem;

  span {
    display: flex;
    background-color: var(--gray-100);
  }
`;
