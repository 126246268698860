import { BrowserRouter, Route, Routes } from "react-router-dom"

import { Home } from "../pages/Home"
import { Trafego } from "../pages/trafego"

export function Router() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/trafego" element={<Trafego />} />
            </Routes>
        </BrowserRouter>
    )
}