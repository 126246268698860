import { Container, Content } from "./styles";
import logo from "../../assets/logo.svg";
import { AiOutlineArrowUp } from "react-icons/ai";

export function Footer() {
  function scroolToTop() {
    window.scroll({ top: 0, left: 0, behavior: "smooth" });
  }

  return (
    <Container>
      <Content>
        <div>
          <a href="https://gbrl.dev.br" target="_blank" rel="noreferrer">
            <img src={logo} alt="logo" />
          </a>
        </div>
        <p>
          <span>Gabriel&nbsp;</span>Silva
        </p>
        <div>
          <button onClick={scroolToTop}>
            <AiOutlineArrowUp />
          </button>
        </div>
      </Content>
    </Container>
  );
}
