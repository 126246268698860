import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  z-index: 1;
  position: fixed;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--background);

  border-bottom: 1px solid var(--gray-100);
`;

export const Content = styled.header`
  width: 100%;
  max-width: 900px;

  height: 10rem;

  display: grid;
  grid-template-columns: repeat(3, 1fr);

  @media (min-width: 420px) {
    height: 6rem;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    &:first-child {
      align-items: initial;
    }

    margin: 0 4rem;

    a {
      width: 30px;
      height: 30px;

      display: flex;
      align-items: center;
      justify-content: center;

      border: 1px solid var(--gray-100);
      border-radius: 5px;

      transition: border .3s;

      svg{
        width: 20px;
        height: 20px;

        color: var(--blue-500);
      }

      &:hover{
        border-color: var(--gray-300);
      }
    }
  }

  p {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 18px;
    font-weight: 200;

    span {
      font-size: 18px;
      font-weight: 500;
    }
  }
`;
