import { Header } from "../../components/Header";
import { Footer } from "../../components/Footer";
import { Container, BodyContainer, H1, H2, P, Project, Social } from "./styles";

import profile from "../../assets/profile.jpg";
import coding from "../../assets/coding.png";

import { GrLinkedinOption, GrGithub, GrInstagram } from "react-icons/gr";
import { Experience } from "../../components/Experience";
import { Skills } from "../../components/Skills";
import { Skill } from "../../components/Skill";
import { Projects } from "../../components/Projects";

export function Home() {
  return (
    <Container>
      <Header />
      <BodyContainer>
        <img src={profile} alt="Profile" />
        <H1> Hello 👋 </H1>
        <P>I'm Gabriel, welcome to my website!</P>
        <H1> Online profile </H1>

        <Social>
          <a href="https://www.linkedin.com/in/gabrielps2/" target="_blank" rel="noreferrer">
            <span>
              <GrLinkedinOption />
            </span>
          </a>
          <a href="https://github.com/gabeps2" target="_blank" rel="noreferrer">
            <span>
              <GrGithub />
            </span>
          </a>
          <a href="https://www.instagram.com/gabriel.ps2/n" target="_blank" rel="noreferrer">
            <span>
              <GrInstagram />
            </span>
          </a>
        </Social>
        <H1> Work Experience </H1>
        <P>
          {" "}
          I've been working with software development for over two years, with front-end experience creating applications in React
          + typescript such as reactive dashboards for monitoring, landing pages and e-commerce sites. Currently I work with
          back-end development, in Java, mainly in the creation of applications in the microservices architecture, following
          clean, reusable and quality code techniques such as SOLID and TDD.
        </P>
        <H1> Technologies </H1>
        <Experience />
        <H2> Languages </H2>
        <Skills>
          <Skill skillLevel="skilled">Java</Skill>
          <Skill skillLevel="skilled">.Net Core</Skill>
          <Skill skillLevel="skilled">Javascript</Skill>
        </Skills>
        <H2>Cloud</H2>
        <Skills>
          <Skill skillLevel="skilled">AWS</Skill>
        </Skills>
        <H2>Agile</H2>
        <Skills>
          <Skill skillLevel="skilled">Jira</Skill>
          <Skill skillLevel="skilled">Scrum</Skill>
          <Skill skillLevel="skilled">Kanban</Skill>
        </Skills>
        <H2>Other skills</H2>
        <Skills>
          <Skill skillLevel="begginer">Spring</Skill>
          <Skill skillLevel="skilled">Micronaut</Skill>
          <Skill skillLevel="skilled">React</Skill>
          <Skill skillLevel="begginer">Next</Skill>
        </Skills>
        <Skills>
          <Skill skillLevel="skilled">SQL Server</Skill>
          <Skill skillLevel="skilled">MongoDB</Skill>
        </Skills>
        <Skills>
          <Skill skillLevel="expert">GIT</Skill>
          <Skill skillLevel="expert">TDD</Skill>
          <Skill skillLevel="skilled">SOLID</Skill>
        </Skills>
        <Skills>
          <Skill skillLevel="skilled">REST API</Skill>
          <Skill skillLevel="skilled">Unit Testing</Skill>
        </Skills>
        <Skills>
          <Skill skillLevel="begginer">New Relic</Skill>
        </Skills>
        <H1>Personal projects</H1>
        <Projects>
          <Project>
            <span>
              <img src={coding} alt="" />
              <p>In development :)</p>
            </span>
          </Project>
          <Project>
            <span>
              <img src={coding} alt="" />
              <p>In development :)</p>
            </span>
          </Project>
          <Project>
            <span>
              <img src={coding} alt="" />
              <p>In development :)</p>
            </span>
          </Project>
        </Projects>
      </BodyContainer>
      <Footer />
    </Container>
  );
}
