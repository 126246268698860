import styled from "styled-components";

export const Container = styled.div`

    display: flex;
    align-items: center;
    justify-content: center;

    flex-direction: column;

    h1{
        font-size: 2rem;
        font-weight: 300;
    }

    div{
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;

        margin: 1.5rem;

        span{
            width: 2rem;
            height: 2rem;
    
            border-radius: 50%;

            margin: 0 1rem;

            background-color: var(--blue-700);
            
            &:first-child{
                background-color: var(--gray-100);            
            }

            &:last-child{
                background-color: var(--red-400);
            }
        }
    }
    img{
        margin-top: .3rem;
        width: 50%;
    }
`;
