import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }

    :root {
        --white: #ffffff;
        --gray-100: #d9d9d9;
        --gray-300: #a8a8b3;
        --gray-800: #29292e;
        --gray-850: #1f2729;
        --gray-900: #121214;

        --red-400: #F05454;
        --green-300: #2CDD84;

        --blue-700: #30475E;
        --blue-500: #0A66C2;

        --cyan-500: #61dafb;
        --yellow-500: #eba417;

        --background: #ffffff;
    }

    @media (max-width: 1920px) {
        html {
            font-size: 57.5%;
        }
    }
    
    @media (max-width: 1600px) {
        html {
            font-size: 57.5%;
        }
    }

    @media (max-width: 1366px) {
        html {
            font-size: 57.5%;
        }
    }

    @media (max-width: 414px) {
        html {
            font-size: 40%;
        }
    }
    
    body {
        background: var(--background);
        color: var(--gray-800);
        overflow-x: hidden;

        scroll-behavior: smooth;

        &::-webkit-scrollbar{
            width: 5px;
        }

        &::-webkit-scrollbar-track {
            background: --var(--white);        /* color of the tracking area */
        }

        &::-webkit-scrollbar-thumb {
            background-color: var(--gray-800);    
            border-radius: 20px;       
        }
    }

    body,
    input,
    textarea,
    select,
    button {
        font: 300 1rem "Poppins", sans serif;
        outline: none;
    }

    button {
        cursor: pointer;
    }

    a {
        color: inherit;
        text-decoration: none;
    }
`;
