import styled from "styled-components";

export const Container = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  border-top: 1px solid var(--gray-100);

  margin-top: 10rem;

  background-color: var(--white);
`;

export const Content = styled.div`
  width: 100%;
  max-width: 900px;
  height: 10rem;

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  @media (min-width: 420px) {
    height: 6rem;
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    &:first-child {
      align-items: initial;
    }

    margin: 0 4rem;

    img {
      width: 20px;
      height: 20px;
    }

    button {
      width: 30px;
      height: 30px;

      display: flex;
      align-items: center;
      justify-content: center;

      background-color: #fff;
      border: 1px solid var(--gray-100);
      border-radius: 50%;

      svg {
        width: 15px;
        height: 15px;
      }

      &:hover {
        border: 1px solid #343434;
      }

      transition: border 0.3s;
    }
  }

  p {
    display: flex;
    align-items: center;
    justify-content: center;

    font-size: 18px;
    font-weight: 200;

    span {
      font-size: 18px;
      font-weight: 500;
    }
  }
`;
