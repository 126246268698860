import styled from "styled-components";

interface SkillProps {
    borderColor?: string;
}

export const Container = styled.div<SkillProps>`
    
    display: flex;
    align-items: center;
    justify-content: center;

    div{
        width: 14rem;
        height: 5rem;

        display: flex;
        align-items: center;
        justify-content: center;

        margin: 1rem;

        border: 1px solid var(--gray-100);
        border-radius: 5px;

        font-size: 2rem;

        border-color: ${props => props.borderColor ? props.borderColor : "var(--gray-100)"};

        display: flex;
        align-items: center;
        justify-content: center;
    }
`;