import styled from "styled-components";

export const Container = styled.div`
  width: 80%;
  display: flex;
  align-items: center;

  flex-direction: column;

  span {
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: var(--white);

    img {
      width: 5rem;
      margin: 2rem;
    }

    p {
      font-size: 2rem;
    }
  }
`;
