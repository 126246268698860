import { Container, Content } from "./styles";
import logo from "../../assets/logo.svg";
import ReactPixel from "react-facebook-pixel";

import { GrLinkedinOption } from "react-icons/gr";

export function Header() {
  function pixelPageView() {
    ReactPixel.init("3031292527091334");
    ReactPixel.pageView();
  }

  return (
    <Container>
      <Content>
        <div>
          <a href="https://gbrl.dev.br/">
            <img src={logo} alt="logo" />
          </a>
        </div>
        <p>
          <span>Gabriel&nbsp;</span>Silva
        </p>
        <div>
          <a href="https://www.linkedin.com/in/gabrielps2/" target="_blank" rel="noreferrer" onClick={pixelPageView}>
            <GrLinkedinOption />
          </a>
        </div>
      </Content>
    </Container>
  );
}
