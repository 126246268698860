import { ReactNode } from "react";
import { Container } from "./styles";

interface SkillProps {
    skillLevel: string;
    children?: ReactNode
}

const colorLevelEnum: { [key: string]: string } = {
    "begginer": "var(--gray-100)",
    "skilled": "var(--blue-700)",
    "expert": "var(--red-400)",
}


export function Skill({ children, skillLevel }: SkillProps) {
    return (
        <Container borderColor={colorLevelEnum[skillLevel]}>
            <div>
                {children}
            </div>
        </Container>
    )
}