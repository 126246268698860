import styled from 'styled-components';

export const Container = styled.a`

    width: 40rem;
    height: 8rem;

    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #2CDD84;
    color: #fff;
    font-size: 2.5rem;
    font-weight: 300;
    
    border-radius: 5px;
    margin: 5rem 0;
    
    @media (min-width: 420px) {
        width: 30rem;
        height: 5rem;
        font-size: 2rem;

        margin: 4rem 0;
    }

    div{
        width: 40rem;
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        @media (min-width: 420px) {
            width: 30rem;
            height: 5rem;
        }

        img{
            width: 4rem;
            height: 4rem;
            color: #fff;

            @media (min-width: 420px) {
                width: 3rem;
                height: 3rem;
            }
        }
    }
`;