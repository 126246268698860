import { Container, H1, P, Social } from "./styles";
import { OrcamentoButton } from "../budgetButton/index";
import mktdigital from "../../assets/mktDigital.png";
import socialMedia from "../../assets/socialMedia.png";

import facebook from "../../assets/facebook.svg";
import instagram from "../../assets/instagram.svg";
import messenger from "../../assets/messenger.svg";
import google from "../../assets/google.svg";

export function Body() {
  return (
    <Container>
      <H1>Traga seu negócio para o futuro, para o digital!</H1>
      <img src={mktdigital} alt="" />
      <P>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
        dummy text ever since the 1500s, when an unknown printer took Lorem Ipsum is simply dummy text of the printing and
        typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
        printer took
      </P>
      <OrcamentoButton />
      <H1>Conheça o Tráfego Pago</H1>
      <img src={socialMedia} alt="" />
      <P>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
        dummy text ever since the 1500s, when an unknown printer took Lorem Ipsum is simply dummy text of the printing and
        typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown
        printer took
      </P>
      <H1>Anuncie seus protudos e serviços onde seus clientes estão</H1>
      <P>
        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard
        dummy text ever since the 1500s, when an unknown printer took
      </P>
      <Social>
        <div>
          <div>
            <img src={facebook} alt="" />
          </div>
        </div>
        <div>
          <div>
            <img src={instagram} alt="" />
          </div>
        </div>
        <div>
          <div>
            <img src={messenger} alt="" />
          </div>
        </div>
        <div>
          <div>
            <img src={google} alt="" />
          </div>
        </div>
      </Social>
      <OrcamentoButton />
    </Container>
  );
}
